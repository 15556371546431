<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title"><b>Qo'yilgan imzo</b></h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <b>APP:</b> {{data.signRequest.appName}}<br/>
                <b>Company:</b> {{data.company.name}}<br/>
                <b>Company INN:</b> {{data.company.tin}}<br/>
                <b>Instance:</b> {{data.signRequest.instance.name}}
              </div>
              <div class="col-4">
                <b>ID:</b> {{ data.id }}<br/>
                <b>KEY ID:</b> {{ data.keyId }}<br />
                <b>ENTITY ID:</b> {{ data.signRequest.entityId }}<br/>
                <b>ENTITY TYPE:</b> {{ data.signRequest.entityType }}<br/>
              </div>
              <div class="col-4">
                <b>SANA:</b> {{ data.createdDate }}<br/>
                <b>STATUS:</b>
                <span class="bg-success" v-if="data.status === 'DONE'">
                    {{ data.status }}
                  </span>
                <span class="bg-danger" v-if="data.status === 'FAILED'">
                    {{ data.status }}
                  </span>
                <span class="bg-warning" v-if="data.status === 'PENDING'">
                    {{ data.status }}
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header mb-3">
            <div class="row">
              <div class="col-6">
                <a target="_blank" :href="data.signedDocument.requestFileUrl">{{data.signedDocument.requestFileUrl}}</a>
                <h4 class="card-title" style="text-align: center"><b>Request Data</b></h4>
              </div>
              <div class="col-6">
                <a target="_blank" :href="data.signedDocument.responseFileUrl">{{data.signedDocument.responseFileUrl}}</a>
                <h4 class="card-title" style="text-align: center"><b>Response Data</b></h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6 req_resp">
                {{data.signedDocument.data.ESP_REQUEST_DATA}}
              </div>
              <div class="col-6 req_resp">
                {{data.signedDocument.data.ESP_RESPONSE_DATA}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!isDone">
      <div class="col-12 text-left">
        <button class="btn btn-danger" @click="reSend"  v-if="hasAction('SIGN_RESEND')">Qayta jo'natish</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title"><b>Bir dokumentga qo'yilgan boshqa imzolar</b></h4>
          </div>
          <div class="card-body">
            <table class="table table-hover">
              <thead>
              <tr>
                <th class="app-w-10p">App</th>
                <th class="app-w-20p">Instance</th>
                <th class="app-w-10p">Entity</th>
                <th class="app-w-10p">Sign Type</th>
                <th class="app-w-15p d-none d-md-table-cell">Imzolovchi</th>
                <th class="app-w-15p d-none d-md-table-cell">Vaqti</th>
                <th class="app-w-10p">Status</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="data.signedDocument.data.ESP_LIST">
              <tr v-for="result in data.signedDocument.data.ESP_LIST" :key="result.id">
                <td>{{ result.signRequest.appName }}</td>
                <td>{{ result.signRequest.instance.name }}</td>
                <td>{{ result.signRequest.entityType }}</td>
                <td>{{ result.signedDocument.espType }}</td>
                <td class="d-none d-md-table-cell">
                  <div v-if="result.createdBy">{{ result.createdBy.fullName }}</div>
                </td>
                <td class="d-none d-md-table-cell">
                  <div v-if="result.createdDate">{{ result.createdDate }}</div>
                </td>
                <td>
                  <span class="bg-success" v-if="result.status === 'DONE'">
                    {{ result.status }}
                  </span>
                  <span class="bg-danger" v-if="result.status === 'FAILED'">
                    {{ result.status }}
                  </span>
                  <span class="bg-warning" v-if="result.status === 'PENDING'">
                    {{ result.status }}
                  </span>
                </td>
                <td>
                  <div class="btn-group btn-group-sm">
                    <router-link :to="'/sign/view/' + result.id" tag="button" class="btn btn-success" v-if="hasAction('SIGN_VIEW')">
                      <i class="fa fa-pencil"></i>
                    </router-link>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignView",
  components: {},
  data() {
    return {
      loading: false,
      data: {
        createdDate: '',
        company: {
          name: "",
          tin: ""
        },
        createdBy: {
          fullName: ''
        },
        signRequest: {
          appName: '',
          instance: {
            name: ''
          }
        },
        signedDocument: {
          data: {
            ESP_REQUEST_DATA: "",
            ESP_RESPONSE_DATA: "",
            ESP_LIST: []
          }
        }
      }
    }
  },
  computed:{
    isDone(){
      return this.data.status === 'DONE' || this.isDoneInOthers
    },
    isDoneInOthers(){
      if (this.data.signedDocument && this.data.signedDocument.data && this.data.signedDocument.data.ESP_LIST && this.data.signedDocument.data.ESP_LIST.length > 0){
        return this.data.signedDocument.data.ESP_LIST.filter(function (data) {
          return data.status === 'DONE'
        }).length > 0
      }
      return false
    }
  },
  methods: {
    init() {
      this.$http.get('sign/' + this.$route.params.id).then(res => {
        this.data = res.data
        console.log(this.data)
      }, err => {
        console.log(err)
      })
    },
    reSend() {
      this.$http.get('sign/re-send/' + this.$route.params.id).then(() => {
        this.$router.push('/sign')
      }, err => {
        console.log(err)
      })
    }
  },
  created() {
    this.init();
  }
}
</script>

<style scoped>
.req_resp{
  height: 400px;
  overflow: scroll;
}
</style>